import React from "react";
import "./Experience.css";

const Experience = () => {
  return (
    <div className="experience" id="Experience">
      <div className="achivement">
        <div className="circle">3+</div>
        <span>Online </span>
        <span>Courses</span>
      </div>
      <div className="achivement">
        <div className="circle">20+</div>
        <span>completed </span>
        <span>Projects</span>
      </div>
      <div className="achivement">
        <div className="circle">0+</div>
        <span>companies </span>
        <span>work</span>
      </div>
    </div>
  );
};

export default Experience;
